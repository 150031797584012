<template>
	<div>
		<v-container>
			<v-row>
				<v-col lg="12" class="pb-1">
					<s-crud
						:filter="filter"
						:config="config"
						:edit="!disabled"
						:remove="items.RseID > 0"
						@save="save($event)"
						searchInput
						@rowSelected="rowSelected($event)"
						title="Recepcion de Acopio"
						no-full
						ref="gridReceptionStorage"
						height="auto"
					>
						<!-- Filtros -->
						<template v-slot:filter>
							<v-container>
								<v-row class="s-col-form">
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Inicio"
											v-model="filter.cDateInitial"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Fin"
											v-model="filter.cDateFin"
										></s-date>
									</v-col>

									<v-col cols="6" lg="3" md="6">
										<s-select-transport
											full
											clearable
											noDefault
											label="Placa"
											v-model="filter.TptEnrollment"
										>
										</s-select-transport>
									</v-col>

									<v-col lg="3" cols="6" md="6">
										<s-select-definition
											v-model="filter.PseState"
											:def="1215"
											label="Estado"
										/>
									</v-col>
								</v-row>
							</v-container>
						</template>

						<template scope="props">
							<v-container>
								<v-row>
									<v-col>
                                        <h2>Datos de Recepción</h2>
                                    </v-col>
								</v-row>
                                <br>

								<v-card
									:disabled="
										props.item.RseID == 0 ? false : true
									"
								>
									<v-row justify="center" style="margin:auto">
										<v-col lg="4" md="4" cols="6"
											><s-date
												label="Fecha Recepcion"
												v-model="props.item.RseDateReception"
											></s-date>
										</v-col>

										<v-col lg="4" md="4" cols="6">
											<!-- :mask="'###-###############'" -->
											<s-text
												label="Nro. Guia Remision Sunshine"
												v-model="props.item.RseNumberGuide" 
											>
											</s-text>
										</v-col>
										<v-col lg="4" md="4" cols="6">
											<!-- :mask="'###-###############'" -->
											<s-text
												label="Nro. Guia Remision Proveedor"
												v-model="props.item.RseNumberGuideCarrier" 
											>
												<!-- <template v-slot:append>
													<i
														style="color: red; font-size: 9px"
														class="fa fa-asterisk"
													></i>
												</template> -->
											</s-text>
										</v-col>
									</v-row>

									<v-row>
										<v-col cols="12">
											<v-data-table
												item-key="PsdID"
												dense
												:items="itemsDetails"
												:headers="headersDetail"
												:items-per-page="-1"
												hide-default-footer
												disable-sort
											>
												<template
													v-slot:item.RsdQuantityReceived="{item}"
												>
													<s-text
														number
														:min="0"
														v-model="item.RsdQuantityReceived"
													>
													</s-text>
												</template>
												<template
													v-slot:item.RsdActions="{item}"
												>
													<v-btn
														@click="removeItem(item)"
														x-small
														color="error"
														elevation="0"
														block
													>
														<v-icon
															style="font-size: 16px !important"
															>fas
															fa-times</v-icon
														>
													</v-btn>
												</template>
											</v-data-table>
										</v-col>
									</v-row>

									<v-row>
										<v-col
											cols="12"
											md="12"
											style="margin-top: 0px"
										>
											<v-data-table
												item-key="PseID"
												dense
												:items="itemsVehicle"
												:headers="headersVehicle"
												v-model="selectedVehicle"
												:items-per-page="-1"
												@click:row="rowClickVehicle"
												hide-default-footer
												disable-sort
											>
												<template
													v-slot:item.TptEnrollment="props"
												>
													<s-select-transport
														:value="props.item.TptEnrollment"
														full
														v-model="props.item.TptEnrollment"
													>
													</s-select-transport>
												</template>

												<template
													v-slot:item.TptDriverName="props"
												>
													<s-select-driver
														clearable
														full
														:value="props.item.TptDriverName"
														v-model="props.item.TptDriverName"
													>
													</s-select-driver>
												</template>
											</v-data-table>
										</v-col>
									</v-row>
								</v-card>
							</v-container>
						</template>
					</s-crud>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import _sReceptionStorageService from "../../../services/Technicalassistance/ReceptionStorageService";
	import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
	import SSelectDriver from "@/components/HarvestSchedule/sSelectDriver.vue";

	export default {
		components: { SSelectTransport, SSelectDriver },
		data: () => ({
			itemsVehicle: [],
			selectedVehicle: [],

			headersVehicle: [
				{ text: "Placa", value: "TptEnrollment", width: 150 },
				{ text: "Chofer", value: "TptDriverName", width: 200 }
			],

			disabled: true,
			itemsampling: {},
			items: {},
			itemsDetails: [],
			dialogWeigh: false,
			BrixGrade: false,
			headersDetail: [
				{ text: "Anular", value: "RsdActions", width: 50 },
				{ text: "Fundo/Lote", value: "FltName", width: 100 },
				{ text: "Localidad", value: "TypeLocationName", width: 100 },
				{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
				{ text: "Tipo de Cultivo", value: "TypeCropName", width: 100 },
				{ text: "Variedad", value: "VrtName", width: 100 },
				{ text: "Cantidad", value: "PsdQuantity", width: 100 },
				{ text: "Cantidad Recibida", value: "RsdQuantityReceived",width: 100},
				{ text: "Tipo de envase", value: "TypeChargeName",width: 100}

			],

			filter: { cDateInitial: null, cDateFin: null, PseState: 1 },
			config: {
				model: {
					Line: "ID",
					PseAproximateArrivaleDate: "date",
					RseDateReception: "date"
				},
				service: _sReceptionStorageService,
				headers: [
					{ text: "#", value: "PseID",   },

					{
						text: "Tipo Transport",
						value: "TypeTransportName",
						 
					},
					{ text: "Socio de Negocio", value: "PrdName",   },
					{ text: "ID Lote", value: "FltName",   },
					{ text: "Empacadora", value: "TypeBalerName", },
					
					{
						text: "Fecha de Recojo",
						value: "PseAproximateArrivaleDate",
						 
					},
					{ text: "Destino", value: "TypeDestinyName",  },
					{ text: "Placa", value: "TptEnrollment", },
					{ text: "Guia Sunshine", value: "RseNumberGuide", width: 100 },
					{ text: "Guia remision", value: "RseNumberGuideCarrier", width: 100 },
					{ text: "Guia Transportista", value: "RseNumberGuideCarrier", width: 120 }, 
					{ text: "Fecha recepcion", value: "RseDateReception", },
					{ text: "Cantidad", value: "PseQuantity", },
					{ text: "Tipo de envase", value: "TypeChargeName", },
					{ text: "Help Guia", value: "LgsNumberGuide", }
				]
			}
		}),
		watch: {},
		methods: {
			rowClickVehicle: function(item, row) {
				this.selectedVehicle = [item];
			},

			rowSelected(item) {
				if (item.length > 0) {
					this.items = item[0];
					console.log("rowSelected", this.items);

					this.itemsVehicle = [];
					let obj = {};

					obj.TptEnrollment = this.items.TptEnrollment;
					obj.TptDriverName = this.items.TptDriverName;
					this.itemsVehicle.push(obj);

					this.disabled = false;

					this.getItemsDetail(item[0].PseID);
				} else {
					this.disabled = true;
				}

				this.itemsampling = item[0];
			},

			getItemsDetail(PseID) {
				_sReceptionStorageService
					.GetDetails(
						{ PseID: PseID, PseState: 1 },
						this.$fun.getUserID()
					)
					.then(r => {
						if(r.status == 200){
							
							this.itemsDetails = r.data;
						}

						/* const search = this.itemsDetails.reduce((acc, element) => {
							acc[element.VrtID] = ++acc[element.VrtID] || 0;
							return acc;
						}, {});

						const duplicate = this.itemsDetails.filter(element => {
							return search[element.VrtID];
						});

						console.log("duplicate", duplicate); */
					});
			},

			isValidatedVehicle() {
				//Validar Grilla Vehiculos
				let isValidated = true;

				if (this.itemsVehicle.length == 0) {
					this.$fun.alert(
						"La Programacion: " +
							this.items.PseID +
							", No cuenta con Vehiculos asignados.",
						"warning"
					);
					isValidated = false;
					return;
				}

				this.itemsVehicle.forEach(element => {
					if (element.TptEnrollment == null) {
						this.$fun.alert(
							"Necesita especificar un Vehiculo",
							"warning"
						);
						isValidated = false;
						return;
					}

					if (element.TptDriverName == null) {
						this.$fun.alert(
							"Necesita especificar un Chofer",
							"warning"
						);
						isValidated = false;
						return;
					}
				});

				return isValidated;
			},

			isValidatedItems(val) {
				var isValidated = true;

				/* if (val.RseNumberGuide.length == 0 || val.RseNumberGuide == null) {
								this.$fun.alert("Ingrese Guia remision", "warning");
								isValidated = false;
								return;
							} */

				/* if (
					val.RseNumberGuideCarrier.length == 0 ||
					val.RseNumberGuideCarrier == null
				) {
					this.$fun.alert(
						"Ingrese guia remision transportista",
						"warning"
					);
					isValidated = false;
					return;
				} */

				this.itemsDetails.forEach(element => {
					if (element.RsdQuantityReceived <= 0) {
						this.$fun.alert(
							"Cantidad recibida deber ser mayor a 0",
							"warning"
						);
						isValidated = false;
						return;
					}

					if (element.RsdQuantityReceived == "") {
						this.$fun.alert(
							"Cantidad recibida deber ser mayor a 0",
							"warning"
						);
						isValidated = false;
						return;
					}
				});

				return isValidated;
			},

			WeighMaterial() {
				this.dialogWeigh = true;
			},
			ModalBrix() {
				this.BrixGrade = true;
			},

			closeWeighed() {
				this.dialogWeigh = false;
				this.$refs.gridReceptionFresh.refresh();
			},

			save(val) {
				if (!this.isValidatedVehicle()) {
					return;
				}
				if (!this.isValidatedItems(val)) {
					return;
				}

				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID();
				val.itemsDetailssss = this.itemsDetails;
				val.TptEnrollment = this.itemsVehicle[0].TptEnrollment;
				val.TptDriverName = this.itemsVehicle[0].TptDriverName;

				console.log("aaaaaaaaaaaaaa", val);

				var objTmp = []
				var one = {}
				if(val.itemsDetailssss > 1){
					
					val.itemsDetailssss.forEach(element => {
						one = val.itemsDetailssss.filter(x => x.VrtID !== element.VrtID)[0]
						objTmp.push(one)
						
					});
				}else{
					val.itemsDetailssss.forEach(element => {
						objTmp.push(element)
						
					});
				}

				
				this.$fun.alert("Seguro de Guardar?", "question")
				.then(r => {

					var objHeader = {}
					var itemsDetail = []

					objTmp.forEach(element => {
						objHeader = {}
						itemsDetail = []
						itemsDetail.push(element)

						objHeader.FltID = element.FltID
						objHeader.PseID = val.PseID
						objHeader.RseNumberGuide = val.RseNumberGuide
						objHeader.RseNumberGuideCarrier = val.RseNumberGuideCarrier
						objHeader.RseDateReception = val.RseDateReception
						objHeader.TptEnrollment = this.itemsVehicle[0].TptEnrollment;
						objHeader.TptDriverName = this.itemsVehicle[0].TptDriverName;
						objHeader.SecStatus = 1;
						objHeader.UsrCreateID = this.$fun.getUserID();
						objHeader.itemsDetail = itemsDetail

						console.log("GUARDAR",objHeader);


							if(r.value){
								_sReceptionStorageService
								.save(objHeader, this.$fun.getUserID())
									.then(resp => {
										if (resp.status == 200) {
											this.$fun.alert("Guardado Correctamente", "success");
											this.$refs.gridReceptionStorage.refresh();
										}
									}
									
								);
							}
					});

				})


					
			},

			removeItem(item) {
				console.log(item);

				if (this.itemsDetails.length == 1) {
					this.$fun.alert(
						"Error, no se puede anular item, la programacion contiene solo un detalle",
						"warning"
					);
					return;
				}
				this.$fun
					.alert("¿Seguro de quitar registro?", "question")
					.then(val => {
						if (val.value) {
							_sReceptionStorageService
								.removeItem(
									{
										PsdID: item.PsdID,
										UsrCreateID: this.$fun.getUserID()
									},
									this.$fun.getUserID()
								)
								.then(resp => {
									if (resp.status == 200) {
										this.$fun.alert(
											"Registro anulado correctamente",
											"success"
										);
										this.getItemsDetail(this.items.PseID);
									}
								});
						}
					});
			}
		}
	};
</script>

<style></style>
